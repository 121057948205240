<template>
  <iframe
    src="https://hmny-governance.netlify.app/#"
    width="100%"
    height="100%"
    >Your browser does not support iframes!
  </iframe>
</template>

<script>

export default {
  name: `page-snapshot`
}
</script>

<style scoped src="../../styles/content-page.css"></style>
